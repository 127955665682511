@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"), url("./fonts/Montserrat-VariableFont_wght.ttf") format("truetype");
}

* {
  font-family: "Montserrat", sans-serif !important;
  font-variation-settings: 'wght' 500;
}

th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium {
  z-index: 0;
}

.amplify-button[data-variation=primary] {
  background-color: rgb(0, 163, 216);
}

.amplify-heading {
  line-height: 24pt;
  display: block;
}

.amplify-image {
  height: 10em;
}

[data-amplify-authenticator] {
  place-self: start;
  margin-top: 5em;
}

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  font-size: 14px;
}

#root {
  overflow: auto;
}

body {
  position: fixed;
  overflow: hidden;
  overscroll-behavior-y: none;
  color: black;
  -webkit-font-smoothing: antialiased;
  background: rgb(148,148,148);
  background: linear-gradient(0deg, rgba(148,148,148,1) 0%, rgba(196,196,196,1) 35%, rgba(204,204,204,1) 100%);
}
